<template>
  <div id="app" class="page-component__scrol" v-cloak>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
      <!-- <router-view v-if="isRresh" /> -->
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
    <el-backtop :visibility-height=400></el-backtop>

    <!-- <router-view v-if="isRresh" /> -->
  </div>
</template>

<script>
export default {
  name: 'YlPcApp',

  provide() {
    return {
      refresh: this.refresh
    }
  },

  created() {

  },
  mounted() {

  },
  data() {

    return {
      isRresh: true,
      showType: this.$store.state.showType
    };
  },
  beforeCreate() {
    document.documentElement.scrollTop = 0;
  },
  created() {
    // document.documentElement.scrollTop = 0;

  },
  beforeDestroy() {
    localStorage.invoiceList = ''
  },
  mounted() {
  },

  methods: {
    refresh() {
      this.isRresh = false;
      this.$nextTick(() => {
        this.isRresh = true;
      })
    }
  },
};
</script>

<style lang="less" >
[v-cloak] {
  display: none;
}

#app {

  .el-backtop,
  .el-calendar-table td.is-today {
    color: #D11304;
  }
}
</style>
