import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user_token: '',
    checked: '',
    setfenYeInfo: {
      pageNumber: '',
      pageSize: '',
      currentPage: '',
      total: '',

    },
    orderInfo: '',
    similerInfo: '',
    invoiceshuJu: '',
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.user_token = token
      localStorage.token = token
    },
    setCheked(state, checked) {
      state.checked = checked
    },
    setfenYeInfo(state, fenye) {
      // state.setfenYeInfo = fenye
      state.setfenYeInfo.total = fenye.total
      state.setfenYeInfo.pageNumber = fenye.pageNumber
      state.setfenYeInfo.pageSize = fenye.pageSize
      state.setfenYeInfo.currentPage = fenye.currentPage
    },
    setOrder(state, order) {
      state.orderInfo = order
      localStorage.setItem("oderInfo", order)
    },
    setSimiler(state, similer) {
      state.similerInfo = similer
      sessionStorage.similer = similer
    },
    setInvoiceshuJu(state, invoiceshuJu) {
      state.invoiceshuJu = invoiceshuJu
      console.log("invoiceshuJu", invoiceshuJu);
    },
  },
  actions: {
  },
  modules: {
  }
})
