import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 图片预览
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer)
// 引入element—UI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// import "reset.css"
import "@/assets/reset.css"

import Quill from 'vue-quill-editor'

// require styles
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(Quill, /* { default global options } */)
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
