import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
//push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  // 登录页面路由
  {
    path: "/login",
    component: () => import("../views/login/index.vue"),
    meta: {
      keepAlive: true,
    }
  },
  // 注册页面路由
  {
    path: "/register",
    component: () => import("../views/register/index.vue"),
    meta: {
      keepAlive: true,
    }
  },
  // 忘记密码页面路由
  {
    path: "/forgetPass",
    component: () => import("../views/forgetPass/index.vue"),
    // meta: {
    //   keepAlive: true,
    // }
  },
  // 首页路由
  {
    path: "/home",
    component: () => import("../views/home/index.vue"),
  },
  // 商品详情页路由
  {
    path: "/goodsInfo",
    name: 'goodsInfo',
    component: () => import("../views/goodsInfo/index.vue"),
  },
  // 搜索页路由
  {
    path: "/searchPage",
    name: 'searchPage',
    component: () => import("../views/searchPage/index.vue"),
  },
  // 搜索无结果页
  {
    path: "/searchNo",
    component: () => import("../views/searchNo/index.vue"),
  },
  //用户服务协议
  {
    path: "/tIntroduce",
    component: () => import("../views/tIntroduce/index.vue"),
  },
  {
    path: '/similarity',
    component: () => import("../views/similarity/index.vue"),//相似商品

  },
  // 查看更多
  {
    path: '/more',
    component: () => import("../views/more/index.vue"),
  },
  // 用户页
  {
    path: "/user",
    component: () => import("../views/user/index.vue"),
    children: [
      {
        path: '/',
        redirect: "center",
        component: () => import("../views/user/center/index.vue"),
      },
      {
        path: 'center',
        component: () => import("../views/user/center/index.vue"),
      },
      {
        path: 'basic',
        component: () => import("../views/user/information/basic.vue"),//基本信息
      },
      {
        path: 'safety',
        component: () => import("../views/user/information/safety.vue"),//安全设置
      }, {
        path: 'mobile',
        component: () => import("../views/user/information/mobile.vue"),//修改手机号
      }, {
        path: 'address',
        component: () => import("../views/user/information/address.vue"),//地址管理
      }, {
        path: 'footprint',
        component: () => import("../views/user/information/footprint.vue"),//我的足迹
      },
      {
        path: 'transaction',
        component: () => import("../views/user/transaction/index.vue"),//我的订单
      }, {
        path: 'transactionInfo',
        component: () => import("../views/user/orderInfo/transactionInfo.vue"),//我的订单详情

      }, {
        path: 'collect',
        component: () => import("../views/user/collect/index.vue"),//我的收藏
      }, {
        path: 'help',
        component: () => import("../views/user/help/index.vue"),//帮助中心
      }

    ]
  },
  // 订单页
  {
    path: "/order",
    component: () => import("../views/order/index.vue"),
  },
  {
    path: "/orderSuccess",
    component: () => import("../views/order/success.vue"),
  },
  // 问题反馈
  {
    path: "/Feedback",
    component: () => import("../views/Feedback/index.vue"),
  },
  // 底部菜单富文本
  {
    path: "/bottomMenu",
    component: () => import("../views/bottomMenu/index.vue"),
  },
  {
    path: "/feedbackSuccess",
    component: () => import("../views/Feedback/success.vue"),
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: '/webpc',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  }
})

// // 全局路由导航守卫
// router.beforeEach((to, from, next) => {
//   // 要跳转的路由是否是去登录页面
//   if (to.path == "/goodsInfo") {
//     // 判断本地是否有token
//     if (localStorage.token) {
//       next()
//     } else {
//       // 放行去要跳转的页面
//       next('/login');
//     }
//   } else if (to.path == "/register") {
//     next();
//   } else if (to.path == "/forgetPass") {
//     next()
//   } else if (to.path == "/tIntroduce") {
//     next()
//   } else {
//     next()
//   }
// })
export default router
